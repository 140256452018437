<template>
  <a-card>
    <div class="query-wrap">
      <a-form-model layout="inline" :model="queryParam" v-bind="{}" ref="queryForm">
        <a-form-model-item label="任务名称" prop="name">
          <a-input v-model="queryParam.name" style="width: 180px" placeholder="请输入任务名称" />
        </a-form-model-item>
        <a-form-model-item label="线路" prop="route">
          <a-select v-model="queryParam.route" style="width: 160px" placeholder="请选择">
            <a-select-option value="">全部</a-select-option>
            <a-select-option v-for="item in routes" :key="item.value" :value="item.value">{{ item.label }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="状态" prop="running">
          <a-select v-model="queryParam.running" style="width: 140px" placeholder="请选择">
            <a-select-option value="">全部</a-select-option>
            <a-select-option :value="true">呼叫中</a-select-option>
            <a-select-option :value="false">待呼叫</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{}">
          <a-space>
            <a-button type="primary" @click="query">查询</a-button>
            <a-button type="default" @click="reset">重置</a-button>
            <a-button type="default" icon="download" @click="download" :loading="downloading" v-if="$checkPermission('Action:IvrTaskData:Export')">导出</a-button>
            <a-button type="danger" @click="add" v-if="$checkPermission('Action:IvrTaskData:Add')">新增</a-button>
            <a-button type="dashed">
              <a href="/IVR 外呼数据模板.xlsx" target="_blank"><a-icon type="file-excel" /> 下载模板</a>
            </a-button>
          </a-space>
        </a-form-model-item>
      </a-form-model>
    </div>

    <s-table ref="table" size="default" :rowKey="record => record.id" :columns="columns" :data="loadData">
      <span slot="running" slot-scope="text, record">
        <a-tag :color="record.running ? 'green' : 'volcano'">{{ record.running ? '呼叫中' : '待呼叫' }}</a-tag>
      </span>

      <span slot="greet" slot-scope="text, record">
        <a-button type="default" size="small" icon="play-square" @click="play(record.greet)">播放</a-button>
      </span>

      <span slot="action" slot-scope="text, record">
        <a-space>
          <a-button
            :type="record.running ? 'danger' : 'primary'"
            size="small"
            :icon="record.running ? 'pause-circle' : 'play-circle'"
            @click="run(record)"
            v-if="$checkPermission('Action:IvrTaskData:Run')"
          >
            {{ record.running ? '暂停' : '启动' }}任务
          </a-button>
          <a-upload name="file" :multiple="true" :customRequest="row => importPhone(row, record.id)" :showUploadList="false" v-if="$checkPermission('Action:IvrTaskData:ImportPhone')">
            <a-button size="small" type="primary" icon="import" :loading="importing">导入号码</a-button>
          </a-upload>
          <template v-if="$checkPermission('Action:IvrTaskData:Modify')">
            <a-button type="primary" size="small" @click="modify(record)">
              编辑
            </a-button>
          </template>
          <template v-if="$checkPermission('Action:IvrTaskData:Delete')">
            <a-button type="danger" size="small" @click="deleted(record.id)">
              删除
            </a-button>
          </template>
        </a-space>
      </span>
    </s-table>

    <!-- TODO: 1. 添加任务 2. 配置安全权限 -->
    <a-modal
      :title="task.id ? '修改任务' : '添加任务'"
      :visible="Object.keys(task).length > 0"
      :centered="true"
      :destroyOnClose="true"
      @cancel="() => (task = {})"
      @ok="submit"
      :confirmLoading="submiting"
    >
      <!--  -->
      <a-form-model ref="taskForm" :model="task" v-bind="formItemLayout">
        <a-form-model-item
          label="任务名称"
          prop="name"
          :rules="{
            required: true,
            message: '请输入任务名称',
            trigger: 'blur'
          }"
        >
          <a-input v-model="task.name" placeholder="请输入任务名称" />
        </a-form-model-item>
        <a-form-model-item
          label="外呼线路"
          prop="route"
          :rules="{
            required: true,
            message: '请选择外呼线路',
            trigger: 'blur'
          }"
        >
          <a-select v-model="task.route" style="width: 160px" placeholder="请选择">
            <a-select-option v-for="item in routes" :key="item.value" :value="item.value">{{ item.label }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item
          label="并发量"
          prop="conclude"
          :rules="{
            required: true,
            message: '请输入并发量',
            trigger: 'blur'
          }"
        >
          <a-input-number v-model="task.conclude" :min="1" :max="100" style="width: 160px" placeholder="请输入并发量" />
        </a-form-model-item>
        <a-form-model-item
          label="IVR 语音"
          prop="greet"
          :rules="{
            required: true,
            message: '请输入菜单开头语音',
            trigger: 'blur'
          }"
        >
          <a-upload
            accept=".wav"
            name="file"
            :action="BASE_URL + '/common/upload/'"
            :headers="headers"
            :file-list="task.greet"
            :remove="
              file => {
                const index = task.greet.indexOf(file)
                const list = task.greet.slice()
                list.splice(index, 1)
                task.greet = list
              }
            "
            @change="
              info => {
                let fileList = [...info.fileList]
                fileList = fileList.slice(-1)
                fileList = fileList.map(file => {
                  if (file.response) {
                    file.url = file.response.body
                  }
                  return file
                })

                task.greet = fileList
              }
            "
          >
            <a-button><a-icon type="upload" />选择文件</a-button>
          </a-upload>
        </a-form-model-item>

        <a-form-model-item
          v-for="(menu, index) in task.menus"
          :key="menu.uuid"
          v-bind="index === 0 ? formItemLayout : formItemLayoutWithOutLabel"
          :label="index === 0 ? 'IVR 菜单' : ''"
          :prop="'menus.' + index + '.key'"
          :rules="{
            required: true,
            message: '请输入菜单配置',
            trigger: 'blur'
          }"
        >
          <a-space>
            <a-input-number v-model="menu.key" :min="0" style="width: 110px" placeholder="请输入按键" />
            <a-select v-model="menu.type" defaultValue="audio" style="width: 80px" placeholder="请选择">
              <a-select-option value="audio">音频</a-select-option>
              <a-select-option value="script">脚本</a-select-option>
            </a-select>
            <a-input v-model="menu.script" placeholder="请输入脚本" v-if="menu.type === 'script'" />
            <a-upload
              v-if="menu.type === 'audio'"
              accept=".wav"
              name="file"
              :action="BASE_URL + '/common/upload/'"
              :headers="headers"
              :file-list="menu.audio"
              :remove="
                file => {
                  const index = menu.audio.indexOf(file)
                  const list = menu.audio.slice()
                  list.splice(index, 1)
                  menu.audio = list
                }
              "
              @change="
                info => {
                  let fileList = [...info.fileList]
                  fileList = fileList.slice(-1)
                  fileList = fileList.map(file => {
                    if (file.response) {
                      file.url = file.response.body
                    }
                    return file
                  })

                  menu.audio = fileList
                }
              "
            >
              <a-button><a-icon type="upload" />选择文件</a-button>
            </a-upload>
            <a-icon v-if="task.menus.length > 1" class="dynamic-delete-button" type="minus-circle-o" :disabled="task.menus.length === 1" @click="removeMenu(menu)" />
          </a-space>
        </a-form-model-item>
        <a-form-model-item v-bind="formItemLayoutWithOutLabel">
          <a-button type="dashed" style="width: 60%" @click="addMenu"> <a-icon type="plus" />添加 IVR 菜单</a-button>
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <a-modal title="播放音频" :visible="visible" :footer="null" :centered="true" :destroyOnClose="true" @cancel="() => (visible = false)">
      <div class="dia-con">
        <audio :src="audioUrl" controls loop autoplay></audio>
      </div>
    </a-modal>
  </a-card>
</template>

<script>
import Vue from 'vue'
import { STable } from '@/components'
import { ACCESS_TOKEN } from '@/store/mutation-types'

import { v4 as uuidv4 } from 'uuid'

export default {
  components: {
    STable
  },
  data() {
    return {
      routes: [],
      formItemLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 4 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 20 }
        }
      },
      formItemLayoutWithOutLabel: {
        wrapperCol: {
          xs: { span: 24, offset: 0 },
          sm: { span: 20, offset: 4 }
        }
      },
      columns: [
        {
          title: '编号',
          dataIndex: 'id',
          width: 60
        },
        {
          title: '任务名',
          dataIndex: 'name'
        },
        {
          title: '线路',
          dataIndex: 'route',
          align: 'center'
        },
        {
          title: '外呼状态',
          dataIndex: 'running',
          align: 'center',
          scopedSlots: { customRender: 'running' }
        },
        {
          title: '并发量',
          dataIndex: 'conclude',
          align: 'center'
        },
        {
          title: '号码数',
          dataIndex: 'total',
          align: 'center'
        },
        {
          title: '已呼数',
          dataIndex: 'called',
          align: 'center'
        },
        {
          title: '等待数',
          dataIndex: 'waitCalled',
          align: 'center'
        },
        {
          title: '接通率',
          dataIndex: 'rate',
          align: 'center',
          customRender: text => Number((text || 0) * 100).toFixed(2) + '%'
        },
        {
          title: 'IVR 语音',
          dataIndex: 'greet',
          ellipsis: true,
          width: 100,
          scopedSlots: { customRender: 'greet' }
        },
        // {
        //   title: 'IVR 菜单',
        //   dataIndex: 'menus',
        //   ellipsis: true,
        //   customRender: text => text
        // },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          width: 160
        },
        {
          title: '操作',
          key: 'action',
          width: 330,
          scopedSlots: { customRender: 'action' }
        }
      ],
      format: 'YYYY-MM-DD HH:mm:ss',
      datetime: undefined,
      downloading: false,
      // 查询条件参数
      queryParam: {
        name: undefined,
        route: undefined,
        running: undefined
      },
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        const params = Object.assign(parameter, this.queryParam)

        return this.$http.get('/ding/task/findAll', { params }).then(res => {
          return res.body
        })
      },
      visible: false,
      audioUrl: '',
      importing: false,
      task: {},
      headers: {
        Authorization: 'Bearer ' + Vue.ls.get(ACCESS_TOKEN)
      },
      submiting: false
    }
  },
  created() {
    this.$http.get('/ding/task/route').then(res => {
      this.routes = res.body
    })
  },
  methods: {
    query() {
      this.$refs.table.refresh()
    },
    reset() {
      this.$refs.queryForm.resetFields()
      this.$refs.table.refresh()
    },
    play(url) {
      this.audioUrl = url
      this.visible = true
    },
    add() {
      this.task = { id: undefined, name: undefined, route: undefined, conclude: undefined, greet: [], menus: [] }
      console.log('add')
    },
    modify(record) {
      const task = { ...record }
      if (task.route) {
        task.route = this.routes.find(item => item.label === task.route)?.value
      }
      if (task.greet) {
        task.greet = [this.toUploadFile(uuidv4(), task.greet)]
      }
      if (task.menus) {
        task.menus = Object.entries(JSON.parse(task.menus)).map(([key, value]) => {
          const isScript = !String(value).endsWith('wav')

          return {
            uuid: uuidv4(),
            key: key,
            type: isScript ? 'script' : 'audio',
            script: isScript ? value : '',
            audio: isScript ? [] : [this.toUploadFile(key, value)]
          }
        })
      }

      this.task = task
    },
    addMenu() {
      const menus = this.task.menus || []
      menus.push({
        uuid: uuidv4(),
        type: 'audio',
        key: String(menus.length + 1),
        script: '',
        audio: []
      })

      this.task.menus = menus
    },
    removeMenu(menu) {
      this.task.menus = this.task.menus.filter(item => item.uuid !== menu.uuid)
    },
    toUploadFile(key, value) {
      return {
        uid: key,
        name: value.split('/').pop(),
        status: 'done',
        url: value
      }
    },
    submit() {
      this.$refs.taskForm.validate(valid => {
        if (valid) {
          const data = {
            id: this.task.id,
            name: this.task.name,
            route: this.task.route,
            conclude: this.task.conclude,
            greet: [...this.task.greet].map(item => item.url).pop(),
            menus: JSON.stringify({})
          }

          if (this.task.menus && this.task.menus.length > 0) {
            data.menus = JSON.stringify(
              this.task.menus.reduce((result, item) => {
                if (item.type === 'script') {
                  result[item.key] = item.script
                } else {
                  result[item.key] = [...item.audio].map(audio => audio.url).pop()
                }

                return result
              }, {})
            )
          }

          this.submiting = true
          this.$http
            .post('/ding/task/' + (this.task.id ? 'modify' : 'add'), data)
            .then(res => {
              if (res.status === 200) {
                this.$message.success('保存成功')
                this.task = {}
                this.$refs.table.refresh()
              }
            })
            .finally(() => (this.submiting = false))
        }
      })
    },
    download() {
      this.downloading = true
      this.$http.get('/ding/task/exportExcel', { params: this.queryParam, responseType: 'blob', download: true, timeout: 0 }).finally(() => (this.downloading = false))
    },
    run(record) {
      this.$http.post('/ding/task/modify', { id: record.id, running: !record.running }).then(res => {
        if (res.status === 200) {
          this.$message.success(`${record.running ? '暂停' : '开启'}成功！`)
        } else {
          this.$message.error(res.message)
        }
        this.$refs.table.refresh()
      })
    },
    importPhone(row, id) {
      this.importing = true

      const data = new FormData()
      data.append('id', id)
      data.append('file', row.file)
      this.$http
        .post('/ding/task/importPhone', data)
        .then(res => {
          if (res.status === 200) {
            this.$message.success(res.body)
          } else {
            this.$message.error(res.message)
          }
          this.$refs.table.refresh()
        })
        .finally(() => (this.importing = false))
    },
    deleted(id) {
      this.$http.get('/ding/task/deleted', { params: { id } }).then(res => {
        if (res.status === 200) {
          this.$message.success('删除成功！')
        } else {
          this.$message.error(res.message)
        }
        this.$refs.table.refresh()
      })
    }
  }
}
</script>

<style scoped>
.query-wrap {
  padding-bottom: 20px;
}

.dia-con {
  display: flex;
  justify-content: center;
}
</style>
